import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // {
  //   path: "/clientId/:clientId?/surveyId/:surveyId?",
  //   name: "Home",
  //   component: Home
  // },
  {
    path: "/surveyItem",
    name: "SurveyItem",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SurveyItem.vue")
  },
  {
    path: "/chart",
    name: "Chart",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ChartContainer.vue")
  },
  {
    path: "/plotly",
    name: "Plotly",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PlotlyContainer.vue")
  },
  {
    path: "/overview/:clientId?/:surveyId?",
    name: "Overview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Overview.vue")
  },
  // {
  //   path: "/evaluation",
  //   name: "Evaluation",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Evaluation.vue")
  // },
  {
    path: "/score",
    name: "Score",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Score.vue")
  },
  {
    path: "/scoreV2",
    name: "ScoreV2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ScoreV2.vue")
  },
  {
    path: "/filterChart",
    name: "FilterChart",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FilterChart.vue")
  }
  // {
  //   path: "/overview/clientId/:clientId?/surveyId/:surveyId?",
  //   name: "Overview",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Overview.vue")
  // }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// den Parameter ClientId aus der URL ...overView/clientID nehmen und im Cookie ueberschreiben
router.beforeEach((to, from, next) => {
  if ( to.name === 'Overview') {
    if ( (Object.prototype.hasOwnProperty.call(to.params, 'clientId')) && (to.params.clientId) ) {
      if ((Vue.$cookies.isKey("clientId")) && (to.params.clientId != Vue.$cookies.get("clientId"))) {
        Vue.$cookies.remove("surveyId");
      }
      Vue.$cookies.set("clientId", to.params.clientId);
    }
  }
  next()
})

export default router