<template>
  <div class="navigation">
    <ul class="customUl">
      <li v-on:click="setLanguage('de')">Deutsch</li>
      <li v-on:click="setLanguage('en')">English</li>
    </ul>
  </div>
 
</template>

<script>
export default {
  name: "NavigationComponent",
  data () {
    return { langs: ['de', 'en'] }
  },
  methods: {
    async setLanguage(inString) {
      this.$i18n.locale = inString;
      this.$cookies.set("siteLanguage", this.$i18n.locale, null, null, null, null, "Strict");
      this.$store.state.locale = this.$i18n.locale;
      
      await this.$getNewItem();
      this.$store.state.compSurveyItemContentKey++;

    }
  }
  // watch: {
  //   // $route() {
  //   //   this.$store.state.showNav = false;
  //   // }
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .navigation {
    flex-direction: row;
  }

  .customUl {
    justify-content: center;
    display: flex;
    font-size: small;
  }

  li {
    padding: 5px;
    cursor: pointer;
  }
</style>
