<template>
  <div></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  // components: {
  // },
  // methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.media {
  display: inherit;
}

.custom_author-image {
  background-color: white;
}

.media-content {
  margin-top: 3rem;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
