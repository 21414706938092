<template>
  <!-- <div id="app" v-bind:class="{ bgWhite: this.$store.state.isEvaluation}"> -->
  <div id="app" class="bgWhite">
    <div class="hero-head">
    </div>
    <router-view />
    <Navigation></Navigation>
  </div>
  <!-- <LanguageSwitcher/> -->
</template>

<script>
import Navigation from "./components/Navigation";
// import LanguageSwitcher from "./components/LanguageSwitcher";

export default {
  name: "App",
  components: {
    // LanguageSwitcher
    Navigation
  }
};
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

html,
body {
  font-family: "Archivo", sans-serif;

  font-weight: 300;
  background: #ffffff;
  // display: flex;
  min-height: 100vh;
  flex-direction: column;
}

// Hintergrund des Div APP weiss, wegen Auswertung
.bgWhite {
  background-color: white;
}

/*ModalTitel*/
.custom_modal-card-title {
  font-size: 1.2rem;
}

/*Button im Accordion Footer*/
.custom_card-footer-item a {
  text-decoration: underline;
}

/*Hoehe des Modalfenster anpassen*/
.custom_modal_card {
  max-height: calc(100vh - 120px);
}

.custom_title {
  font-family: "Archivo", sans-serif;
  color: #363636;
  font-weight: 100;
}

.button {
  font-family: "Archivo", sans-serif;
}

/*Buttons anpassen*/
.custom_button {
  min-width: 250px;
}

.custom_button_div {
  text-align: center;
  display: unset;
}

.custom_select:not(.is-multiple):not(.is-loading)::after {
  border-color: #4a4a4a;
}

.custom_accordion {
  font-weight: 300;
  text-align: justify;
}

.hero.is-success {
  background: #f2f6fa;
}
.hero .nav,
.hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.1);
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
}
input {
  font-weight: 300;
}

p.subtitle {
  padding-top: 1rem;
}

a {
  color: #363636;
}
.login-hr {
  border-bottom: 1px solid #363636;
}

.has-text-black {
  color: black;
}

.field {
  padding-bottom: 10px;
}

.navbar.is-white {
  background: #f0f2f4;
}
.navbar-brand .brand-text {
  /*  font-size: 1.11rem;*/
  font-weight: bold;
}

.navbar-item img {
  max-height: 1.5rem;
}

.navbar-link.is-active,
.navbar-link:focus,
.navbar-link:focus-within,
.navbar-link:hover,
a.navbar-item.is-active,
a.navbar-item:focus,
a.navbar-item:focus-within,
a.navbar-item:hover {
  color: #b5b5b5;
  background-color: #ffffff;
}

.card {
  border-radius: 20px;
  margin-top: 20px;
}

.author-image {
  position: absolute;
  top: -30px;
  left: calc(50% - 40px);
  width: 80;
  height: 80px;
  /*margin-left: -30px;*/
  border: 3px solid #ccc;
  border-radius: 50%;
}
.media-center {
  display: block;
  margin-bottom: 2rem;
}

/*Geloescht von Dress, warum wird es benoetig*/
.media-content_custom {
  margin-top: 3rem;
}
.article,
.promo-block {
  margin-top: 3rem;
}

.article-title {
  font-weight: lighter;
}
.article-subtitle {
  color: #909aa0;
  margin-bottom: 3rem;
}
.article-body {
  line-height: 1.4;
  /*  margin: 0 6rem;*/
}
.promo-block .container {
  margin: 1rem 5rem;
}

.social img {
  max-width: 30px;
  height: auto;
}

/*Modal-Fenster Abstand vom Rand*/
.modal {
  flex-direction: unset;
}

/*Spinner*/

.plus {
  display: flex;
  margin: 0 auto;
  /*  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;*/
}

.plusNone {
  display: none;
}

.pluswrap {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 1000;
  top: 0;
  font-size: 18vw;
  letter-spacing: -1vw;
}

body {
  margin: 0;
}

.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: white;
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
</style>
