import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import router from "./router";
import store from "./store";

import VueCookies from "vue-cookies";
import "bulma/css/bulma.css";
import "bulma-o-steps/bulma-steps.min.css";

import axios from "axios";
import VueAxios from "vue-axios";

import VueMobileDetection from "vue-mobile-detection";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

// import VueScrollTo from "vue-scrollto";
import VueSmoothScroll from 'vue2-smooth-scroll'

import VueI18n from 'vue-i18n';
import messages from './locales';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { faArrowsAltH } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faQuestion} from "@fortawesome/free-solid-svg-icons";

library.add(faHome);
library.add(faRedoAlt);
library.add(faSignOutAlt);
library.add(faChalkboardTeacher);
library.add(faArrowsAltH);
library.add(faEllipsisH);
library.add(faQuestionCircle);
library.add(faQuestion);
library.add(faFilter);

Vue.component('v-select', vSelect);

Vue.use(VueCookies);
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.use(VueMobileDetection);
Vue.use(VueSmoothScroll)
Vue.use(VueI18n)

// export const i18n = new VueI18n({
//   locale: 'de',
//   fallbackLocale: 'de',
//   messages
// });
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: 'de', // set locale
  fallbackLocale: 'de',
  messages, // set locale messages
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  computed: {
    //isLoggedIn() {
    //  return this.$cookies.isKey("userBearerAuthorization")
    //},
    isSiteIn() {
      return this.$cookies.isKey("siteBearerAuthorization");
    }
  },
  data: {
    window: {
        width: 0,
        height: 0
    }
  },
  created: function() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    // set sameSite - should be one of `None`, `Strict` or `Lax`. Read more https://web.dev/samesite-cookies-explained/
    // this.$cookies.set("use_path_argument","value",null, null, null, null, "Lax");
    // Entwicklung
    //this.$cookies.set("siteApi", "https://api.psaichology.org/v1", null, null, null, null, "Strict");
    //this.$cookies.set("siteNetApi", "https://net-api2.psaichology.org/v1", null, null, null, null, "Strict");
    //this.$cookies.set("siteAuthorization", "YzgxZmYwNjgtNmI3NC0xMWVhLWJkMDEtNTI1NDAwZWY1MTE3OlBKVkZ5VE5SNkdIbkJYQzlk", null, null, null, null, "Strict");
    //this.$cookies.set("userAuthorization", "Y2xpZW50X3dlYlVzZXI6UEpWRnlUTlI2R0huQlhDOWQ=", null, null, null, null, "Strict");
    
    if (!this.$cookies.isKey("clientId")) {
      this.$cookies.set("clientId", "008796cd-df31-11ea-9973-4aaad0144f98"); // Standard-Client psaichology
      // this.$cookies.set("clientId", "892630f6-fac2-11ea-9973-4aaad0144f98");  // BentleyTalent
      // this.$cookies.set("surveyId", "a005f8eb-07ba-11ea-b729-525400ef5117");  // BentleyTalent Intelligenz
    }

    if (!this.$cookies.isKey("siteLanguage")) {
      this.$cookies.set("siteLanguage", this.setLanguageCode(navigator.language), null, null, null, null, "Strict");
    }

    this.$i18n.locale = this.$cookies.get("siteLanguage");
    this.$store.state.locale = this.$i18n.locale;

    sessionStorage.langdeError = JSON.stringify({
      "300": {
        userconfirm: {
          title: "Hinweis",
          description:
            "Herzlichen Glückwunsch. Ihre Benutzer wurde freigeschaltet.<br><br><a href='/'><div class='buttons has-addons is-centered'><button class='button is-success is-centered'>Zur Anmeldung</button></div>"
        },
        userpostNewUser: {
          title: "Hinweis",
          description:
            "Der Benuzter wurde erfolgreich angelegt, eine E-Mail zum Bestätigen wurde versendet."
        },
        surveyevaluation: {
          "10100": {
            title: "Oops...",
            redirect: "score",
            apiExecute: "netScoreValueRenew",
            description:
              "Dein OCEAN Score wurde nicht berechnet. Die Berechnung wird jetzt neu durchgeführt."
          }
        },
        surveyevaluationNew: {
          "10100": {
            title: "Hinweis",
            redirect: "score",
            apiExecute: "netScore",
            description:
              "Dein OCEAN Score wurde nicht berechnet. Die Berechnung wird jetzt neu durchgeführt."
          }
        }
      },
      "401": {
        "invalid_grant": {
          "Invalid username and password combination": {
            title: "Fehler",
            description: "Ungültige Benutzername und Passwortkombination."
          }
        },
        "invalid_token": {
          "The access token provided is invalid": {
            title: "Fehler",
            description: "Die Sitzung ist ist ungültig."
          },
          "The access token provided has expired": {
            title: "Fehler",
            description: "Die Sitzung ist ist abgelaufen."
          }
        }
      },
      "404": {
        userpostNewUser: {
          "1062": {
            title: "Fehler",
            description: "Der Benuzter exisitert bereits."
          }
        }
      },
      "422": {
        validate: {
          title: "Fehler",
          description: "Bitte füllen Sie die Felder richtig aus."
        }
      }
    });

    // ENDE

    if (this.$cookies.isKey("userBearerAuthorization")) {
      this.$store.state.isLoggedIn = true;
    }

    axios
      .post(
        "/getToken",
        {
          "grant_type": "client_credentials"
        },
        this.$store.state.axiosSiteConfig
      )
      .then(response => {
        // getToken Token der Seite in den Store schreiben, fuer Language, LoginUser, RegisterUser
        this.$store.state.axiosSiteBearer.headers.Authorization =
          "Bearer " + response.data.access_token;
        const date = new Date(Date.now() + response.data.expiresIn * 1000);
        this.$store.state.expiresIn = date;
        const stringDate = date.toUTCString();
        this.$cookies.set(
          "siteBearerAuthorization",
          response.data.access_token,
          stringDate,
          null, null, null, "Strict"
        );

        // Wenn Client und die Umfrage/Test in Cookies gesetzt ist, dann hole dir die komplette Uebersicht
        // Eventuell anpassen, Client ID reicht
        //if ( this.$cookies.isKey("clientId") && this.$cookies.isKey("surveyId") ) {
        if ( this.$cookies.isKey("clientId") ) {
          return this.$router.push({ path: "/overview" });
        } else {
          console.log("Error: ClienId is missing");
        }

      })
      .catch(error => {
        this.$checkAxiosError(error);
      });
  },
  mounted() {
    // Parameter die beim Aufurf per URL mitgegebene werden koennten uberpruefen
    if (this.$route.params.clientId) {
      this.$cookies.set("clientId", this.$route.params.clientId, null, null, null, null, "Strict");
    }

    if (this.$route.params.surveyId) {
      this.$cookies.set("surveyId", this.$route.params.surveyId, null, null, null, null, "Strict");
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    setLanguageCode(inLang) {

      let outLang = 'de'; // Standard
      
      switch (inLang) {

        case 'de':
        case 'de-DE':
          outLang = 'de';
          break;

        case 'en':
        case 'en-EN':
        case 'en-US':
          outLang = 'en';
          break;

        default:

      }

      return outLang
    },
    handleResize() {
      if ( 
        ( (this.$store.state.windowSize.width > 0) &&  (this.$store.state.windowSize.height > 0) ) && 
        ( 
          ( (this.$store.state.windowSize.width < window.innerWidth) && (this.$store.state.windowSize.height > window.innerHeight) ) || 
          ( (this.$store.state.windowSize.width > window.innerWidth) && (this.$store.state.windowSize.height < window.innerHeight) ) ||
          ( (this.$store.state.windowSize.width > window.innerWidth) && (this.$store.state.windowSize.height > window.innerHeight) ) ||
          ( (this.$store.state.windowSize.width < window.innerWidth) && (this.$store.state.windowSize.height < window.innerHeight) )
        )
        ) {
        // Wenn Breite und Hoehe sich gleichzeitig aendern von Horizontal in Vertical
        this.$store.state.windowSize.width = window.innerWidth;
        this.$store.state.windowSize.height = window.innerHeight;

        this.$store.state.compAmChartRadar++;
        this.$store.state.compAmChartBar++;
        this.$store.state.compAmChartTree++;
        this.$store.state.compAmChartMix++;
      }
      else if ( (this.$store.state.windowSize.width == 0) &&  (this.$store.state.windowSize.height == 0) ) {
        this.$store.state.windowSize.width = window.innerWidth;
        this.$store.state.windowSize.height = window.innerHeight;

        this.$store.state.compAmChartRadar++;
        this.$store.state.compAmChartBar++;
        this.$store.state.compAmChartTree++;
        this.$store.state.compAmChartMix++;
      }
      // else {
      //   console.log("Resize nur eine Richtung");
      // }
    }
  }
}).$mount("#app");

// Globale Methoden

Vue.prototype.$alert = function(inObject) {
  this.$swal(inObject);
};

Vue.prototype.$postUserAnswer = function(inUuidItem) {
  if (this.$store.state.surveyItems[0].valueItem === undefined) {
    this.$store.state.surveyItems[0].valueItem = this.$store.state.surveyItems[0].userAnswer;
  }

  if (!inUuidItem) {
    inUuidItem = this.$store.state.surveyItems[0].uuidItem;
  }

  // Object zum Speichern bilden, Calc wird benutzt, um nach einer Antwort ein Ergbenis zurueckzubekommen
  const tmpObject = {
    parentChildForeignKey: this.$store.state.surveyItems[0]
      .uuidItemParentChild,
    itemForeignKey: inUuidItem,
    responseValue: this.$store.state.surveyItems[0].valueItem,
    surveyForeignKey: this.$cookies.get("surveyId"),
    responseType: "answer",
    language: this.$cookies.get("siteLanguage"),
    calc: false
  };

  // Prueft ob die aktuelle Frage die Eigenschaft Calc auf True hat, wenn ja dann im Object aendern
  if (Object.prototype.hasOwnProperty.call(this.$store.state.surveyItems[0].properties, "calc")) {
    if (this.$store.state.surveyItems[0].properties.calc) {
      tmpObject.calc = this.$store.state.surveyItems[0].properties.calc;
    }
  }

  return this.axios
    .post(
      "/survey/answer/postUserNewAnswer",
      tmpObject,
      this.$store.state.axiosUserBearer
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      this.$checkAxiosError(error);
    });
};

Vue.prototype.$getEvaluation = function() {
  // Fragt die Auswertung inklusive Texte ab
  const tmpObject = {
    getJustEvaluation: true,
    currentEvaluation: this.$store.state.evaluationId,
    survey: this.$store.state.surveyId,
    global: {
      facet: true,
      dimension: true
    },
    language: this.$cookies.get("siteLanguage")
  };

  return this.axios
    .post(
      "/survey/evaluation",
      tmpObject,
      this.$store.state.axiosUserBearer
    )
    .then(response => {
      return response;
    })
    .catch(error => {
      this.$checkAxiosError(error);
    });
};

Vue.prototype.$setUserLogin = function(inObject) {

  const date = new Date(Date.now() + inObject.data.expiresIn * 1000);
  const stringDate = date.toUTCString();
  this.$store.state.userExpiresIn = stringDate;
  this.$cookies.set(
    "userBearerAuthorization",
    inObject.data.access_token,
    date, null, null, null, "Strict"
  );
  this.$store.state.axiosUserBearer.headers.Authorization =
    "Bearer " + inObject.data.access_token;
  this.$store.state.axiosUserNetBearer.headers.Authorization =
    "Bearer " + inObject.data.access_token;

  this.$store.state.isLoggedIn = true; // change data
  this.$store.state.userScope = inObject.data.scope;  // setzt den Scope/Berechtigung des Users in den Store
  this.$store.state.userRefreshToken = inObject.data.refreshToken

};

Vue.prototype.$getNewItem = async function () {

    return await this.axios
    .post(
      "/survey/newItem",
      {
        // "T1.parentForeignKey": this.$store.state.surveyId,
        "T1.parentForeignKey": this.$cookies.get("surveyId"),
        language: this.$cookies.get("siteLanguage")
      },
      this.$store.state.axiosUserBearer
    )
    .then(response => {
      function stringInJson(inObject) {
        for (const [key, value] of Object.entries(inObject)) {
          if (value) {
            if (typeof value === "string") {
              try {
                const tmpJson = JSON.parse(value);
                // Wenn erfolgreich umgewandelt werden konnte
                if (tmpJson && typeof tmpJson === "object") {
                  // Object wieder zurueckschreiben
                  inObject[key] = tmpJson;
                  stringInJson(inObject[key]);
                }
              } catch {
                // error in the above string (in this case, yes)!
              }
            } else if (typeof value === "object") {
              if (value.isArray) {
                // console.log("isArray:", value);
              } else {
                stringInJson(value);
              }
            }
          }
        }

        return inObject;
      }

      // Umwandeln von String in Object
      response.data.data.forEach(function(element, indexElement) {
        stringInJson(response.data.data[indexElement]);
      });

      // Progressbar bestimmen
      const responseObject = response.data;
      if (
        Object.prototype.hasOwnProperty.call(
          responseObject,
          "countSurveyChilds"
        )
      ) {
        this.$store.state.progressbar.current = parseInt(
          responseObject.countUserAnswers
        );
        this.$store.state.progressbar.max = parseInt(
          responseObject.countSurveyChilds
        );
      }

      // Original:
      this.$store.state.parentCiOrder = response.data.parentCiOrder;
      this.$store.state.surveyItems = response.data.data;
    })
    .catch(error => {
      this.$checkAxiosError(error);
    });
}

Vue.prototype.$logout = function() {
  this.$cookies.remove("userBearerAuthorization");
  this.$cookies.remove("surveyId");
  //this.$cookies.set("clientId", "c81ff068-6b74-11ea-bd01-525400ef5117"); // Standard-Client psaichology
  this.$store.state.isLoggedIn = false;
  //sessionStorage.clear();
  localStorage.clear();
  // this.$router.push({ path: "/" });
  // this.$router.push({ path: '/' }).catch();
  // this.$router.history._startLocation = "/";
  this.$router.push({ path: "/" });
  window.location.reload();
};

// AXIOS Error Behandlung
Vue.prototype.$checkAxiosError = function(inError) {
  if (inError.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // Token abgelaufen

    switch (inError.response.status) {
      case 300: {
        const tmpUrl = inError.response.config.url.replace(
          new RegExp("/", "g"),
          ""
        );

        //if (sessionStorage['lang' + this.$cookies.get('siteLanguage') + 'Error']) {
        const objectError = JSON.parse(
          sessionStorage["lang" + this.$cookies.get("siteLanguage") + "Error"]
        );
        //}

        if (
          Object.prototype.hasOwnProperty.call(objectError, [
            inError.response.status
          ])
        ) {
          if (
            Object.prototype.hasOwnProperty.call(
              objectError[inError.response.status],
              [tmpUrl]
            )
          ) {
            const tmpData = objectError[inError.response.status][tmpUrl];

            switch (tmpData) {

              case "title": {
                this.$swal.fire({
                  title: objectError[inError.response.status][tmpUrl].title,
                  text:
                    objectError[inError.response.status][tmpUrl].description,
                  icon: "error"
                });

                break;
              }

              default: {

                const keyWord = String(Object.keys(tmpData));
                this.$swal
                  .fire({
                    title:
                      objectError[inError.response.status][tmpUrl][keyWord]
                        .title,
                    text:
                      objectError[inError.response.status][tmpUrl][keyWord]
                        .description,
                    icon: "error",
                    showCancelButton: false,
                    showCloseButton: true,
                    showConfirmButton: true
                  })
                  .then(result => {
                    if (
                      Object.prototype.hasOwnProperty.call(
                        tmpData[keyWord],
                        "apiExecute"
                      )
                    ) {
                      // app.apiExecute(tmp_data[Object.keys(tmp_data)].apiExecute);
                      console.log(result);
                    }
                  });
                }
            }
          }
        }

        break;
      }

      case 401: {
        //if (sessionStorage['lang' + this.$cookies.get('siteLanguage') + 'Error']) {
        
        // NEU: 
        // const objectError = JSON.parse(
        //   sessionStorage["lang" + this.$cookies.get("siteLanguage") + "Error"]
        // );
        // //}

        // if (objectError) {
        //   // Wenn der Sprachfile fuer Fehler exisitert
        //   this.$store.state.alert.title =
        //     objectError[inError.response.status][inError.response.data.error][
        //       inError.response.data.error_description
        //     ].title;
        //   this.$store.state.alert.text =
        //     objectError[inError.response.status][inError.response.data.error][
        //       inError.response.data.error_description
        //     ].description;
        // } else {
        //   // Wenn keiner existiert, dann original Meldung benutzen
        //   this.$store.state.alert.title = inError.response.data.error;
        //   this.$store.state.alert.text =
        //     inError.response.data.error_description;
        // }

        // if (
        //   inError.response.data.error == "invalid_token" ||
        //   inError.response.data.error == "invalid_grant"
        // ) {
        //   this.$swal
        //     .fire({
        //       title: this.$store.state.alert.title,
        //       text: this.$store.state.alert.text,
        //       icon: "error",
        //       showCancelButton: false,
        //       showCloseButton: true,
        //       showConfirmButton: true
        //     })
        //     .then(() => {
        //       this.$logout();
        //     });
        // }

        // Token ERROR
        this.$logout();
        // this.$router.push({ path: "/" });
        //this.$router.go(this.$router.currentRoute)
        break;
      }

      case 404: {
        // app.$refs.custom_modal.$refs.custom_modal_content.classList.toggle('is-active');

        //if (sessionStorage['lang' + this.$cookies.get('siteLanguage') + 'Error']) {
        const objectError = JSON.parse(
          sessionStorage["lang" + this.$cookies.get("siteLanguage") + "Error"]
        );
        //}

        if (objectError) {
          // Wenn der Sprachfile fuer Fehler exisitert

          const tmpUrlError = inError.response.config.url.replace(
              new RegExp("/", "g"),
              ""
            );
          // this.$store.state.alert.title =
          //   objectError[inError.response.status][[tmpUrlError]][
          //     inError.response.data.error
          //   ].title;
          this.$store.state.alert.title =
              objectError[inError.response.status][tmpUrlError][
                inError.response.data.error
              ].title;
          // this.$store.state.alert.text =
          //   objectError[inError.response.status][[tmpUrlError]][
          //     inError.response.data.error
          //   ].description;
          this.$store.state.alert.text =
              objectError[inError.response.status][tmpUrlError][
                inError.response.data.error
              ].description;
        } else {
          // Wenn keiner existiert, dann original Meldung benutzen
          this.$store.state.alert.title = inError.response.data.error;
          this.$store.state.alert.text =
            inError.response.data.error_description;
        }

        this.$swal
          .fire({
            title: this.$store.state.alert.title,
            text: this.$store.state.alert.text,
            icon: "error",
            showCancelButton: false,
            showCloseButton: true,
            showConfirmButton: true
          })
          .then(() => {
            this.$logout();
          });

        break;
      }

      default:
        break;
    } // ENDE Switch
  } else if (inError.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(inError.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", inError.message);
  }
};
